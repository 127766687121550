import { initStripe } from '@src/components/payment/stripe'
import { initCloudinary } from '@src/lib/Cloudinary'

import { initHistory } from './history'
import initOneTrust from './initOneTrust'
import initSegmentAnalytics from './initSegmentAnalytics'
import initZendesk from './initZendesk'

export default async function initAllServices() {
  // Segment
  await initSegmentAnalytics() //We need this to finish before continueing to ensure we can identify properly.

  // cloudinary
  initCloudinary()

  // history
  initHistory()

  // One Trust
  initOneTrust()

  // Zendesk
  initZendesk()

  // Stripe
  initStripe()
}
